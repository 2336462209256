import _parse from "./parse.js";
import _compile from "./compile.js";
var exports = {};
var parse = _parse,
    compile = _compile;

exports = function nthCheck(formula) {
  return compile(parse(formula));
};

exports.parse = parse;
exports.compile = compile;
export default exports;
const _parse2 = exports.parse,
      _compile2 = exports.compile;
export { _parse2 as parse, _compile2 as compile };