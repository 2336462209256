import _boolbase from "boolbase";
var exports = {};
exports = compile;
var BaseFuncs = _boolbase,
    trueFunc = BaseFuncs.trueFunc,
    falseFunc = BaseFuncs.falseFunc;
/*
	returns a function that checks if an elements index matches the given rule
	highly optimized to return the fastest solution
*/

function compile(parsed) {
  var a = parsed[0],
      b = parsed[1] - 1; //when b <= 0, a*n won't be possible for any matches when a < 0
  //besides, the specification says that no element is matched when a and b are 0

  if (b < 0 && a <= 0) return falseFunc; //when a is in the range -1..1, it matches any element (so only b is checked)

  if (a === -1) return function (pos) {
    return pos <= b;
  };
  if (a === 0) return function (pos) {
    return pos === b;
  }; //when b <= 0 and a === 1, they match any element

  if (a === 1) return b < 0 ? trueFunc : function (pos) {
    return pos >= b;
  }; //when a > 0, modulo can be used to check if there is a match

  var bMod = b % a;
  if (bMod < 0) bMod += a;

  if (a > 1) {
    return function (pos) {
      return pos >= b && pos % a === bMod;
    };
  }

  a *= -1; //make `a` positive

  return function (pos) {
    return pos <= b && pos % a === bMod;
  };
}

export default exports;