var exports = {};
exports = parse; //following http://www.w3.org/TR/css3-selectors/#nth-child-pseudo
//[ ['-'|'+']? INTEGER? {N} [ S* ['-'|'+'] S* INTEGER ]?

var re_nthElement = /^([+\-]?\d*n)?\s*(?:([+\-]?)\s*(\d+))?$/;
/*
	parses a nth-check formula, returns an array of two numbers
*/

function parse(formula) {
  formula = formula.trim().toLowerCase();

  if (formula === "even") {
    return [2, 0];
  } else if (formula === "odd") {
    return [2, 1];
  } else {
    var parsed = formula.match(re_nthElement);

    if (!parsed) {
      throw new SyntaxError("n-th rule couldn't be parsed ('" + formula + "')");
    }

    var a;

    if (parsed[1]) {
      a = parseInt(parsed[1], 10);

      if (isNaN(a)) {
        if (parsed[1].charAt(0) === "-") a = -1;else a = 1;
      }
    } else a = 0;

    return [a, parsed[3] ? parseInt((parsed[2] || "") + parsed[3], 10) : 0];
  }
}

export default exports;